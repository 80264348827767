/* body{
    margin-bottom: 30px;
} */
.createticket {
    background-color: #FAFAFA;
    padding: 20px;
    border-radius: 10px;
    justify-content: center;
}

.block-input {
    border-radius: 10px;
    border: 2px solid #eee;
    width: 98%;
    padding: 10px 10px;
    font-size: 15px;
    font-weight: 400;
    margin-block-end: 15px;
}
    .block-input:hover {
        border-color: #95C160;
    }
    .block-input:focus {
        outline: none !important;
        border-color: #95C160;
        box-shadow: 0 0 10px #95C160;
    }

.message {
    border-radius: 0px;
    border: 2px solid #eee;
    width: 99.6%;
    padding: 10px 10px;
    font-size: 22px;
    font-weight: 400;
    margin-block-end: 15px;
    resize: none;
}
    .message:hover {
        border-color: #95C160;
    }
    .message:focus {
        outline: none !important;
        border-color: #95C160;
    }

select {
    border-radius: 10px;
    padding: 5px;
}
    select:hover {
        border-color: #95C160;
        box-shadow: 0 0 5px #95C160;
    }
    select:focus {
        outline: none !important;
        border-color: #95C160;
        box-shadow: 0 0 10px #95C160;
    }


.table {
    border-collapse: collapse;
}

    .table,
    .table th,
    .table td {
        border: 1px solid none;
    }

        .table th,
        .table td {
            padding: 0.5rem;
        }

        .table th {
            position: relative;
        }

.resizer {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    cursor: col-resize;
    user-select: none;
}

    .resizer:hover,
    .resizing {
        border-right: 2px solid blue;
    }

.resizable {
    border: 1px solid gray;
    height: 100px;
    width: 100px;
    position: relative;
}

.footer {
    position: fixed;
    z-index: 1000;
    left: 0;
    bottom: 0;
    padding-block: 10px;
    width: 100%;
    border-top: 1px solid #e5e5e5;
    color: black;
    font-size: 14px;
    text-align: center;
    background-color: #FAFAFA;
    justify-content: space-between;
  }

.col-form-label {
    padding-bottom: 0;
}

.container-no-padding {
    padding-inline: 0;
}

#ticket .sidebar {
    background-color: #FAFAFA;
    min-height: fit-content;
    border-left: 1px solid #b4bbc6;
    box-shadow: .5px;
}

#ticketDetail .navbar {
    background: #fcfcfc;
}

.navmain {
    background: #f6f4f4;
    width: 100%;
    padding-inline: 31px;
    padding-block: 16px;
}

.mainproblem {
    text-indent: 30px;
}

.main {
    flex-direction: column;
    width: 78%;
    min-height: 100vh;
}

@media (min-width: 1919px) {
    .main {
      max-width: auto !important;
      top: 60px;
    }
  }

/* @media (min-width: 1080px) {
    .main {
      width: 1605px;
      top: 60px;
    }
    .main .container {
        max-width: 1605px;
    }
  } */

@media (max-width: 1180px) {
    /* #message {
        min-height: 680px;
    } */

    #messageinput {
        min-height: 135px !important;
        max-height: 135px !important;
    }
}

@media (max-width: 1299px) {
    .headSide {
        width: 100%;
        flex-wrap: wrap;
        display: flow-root;
        justify-content: space-between;
    }
    .main {
        top: 60px;
        min-width: 100%;
    }
    .navmain {
        width: 100%;
    }
    .message {
        width: 100%;
    }
    .inputmsg{
        max-width: 1290px;
    }
  }

@media (max-width: 821px) {
    .headSide {
        width: 100%;
        justify-content: space-between;
    }
    .headSideCol {
        width: fit-content;
    }
}

@media (max-width: 690px) {
    .headSide {
        top: 0px;
        position: relative;
        display: block;
    }
  }

@media (max-width: 60px) {
    .mainprob {
        margin-top: 0px;
    }
}

::selection {
    padding: 50px;
    margin: 50px;
    background-color: #a1ff2e25;
    color: #000;
}

/* option { 
    background-color: #0e0e0e;
}

option:hover {
    box-shadow: 0 0 10px 100px #1882A8 inset;
}

option:not(:checked) {
    background-color: #fff;
  } */


.content {
    padding: 1rem;
    padding-bottom: 2%;
    background-color: white;
    width: 100%;
    min-width: 200px;
    min-height: calc(100vh / 5);
    overflow-y: auto;
}
.content:first-child{
    margin-top: -500px;
}
.edit-input {
    display: none;
}

.control-label .text-info {
    display: inline-block;
}


/*Style for activity message!*/

#message ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

    #message ul li {
        display: inline-block;
        clear: both;
        padding: 15px;
        border-radius: 20px;
        font-size: 16px;
        margin-bottom: 10px;
        font-family: Helvetica, Arial, sans-serif;
    }

.Admin {
    float: left;
    background: #eee;
    margin-right: 15%;
    margin-bottom: 5%;
}

.Customer {
    float: right;
    background: #95C160;
    color: #fff;
    margin-left: 15%;
    margin-bottom: 5%;
}

.Admin + .Customer {
    border-bottom-right-radius: 2px;
}

.Admin + .Admin {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.Admin:last-of-type {
    border-bottom-right-radius: 15px;
}





/*
  NECESSARY SECTION
*/
.mainprob {
    background: #f6f4f4;
    height: 100%;
    overflow: hidden;
    padding-block-start: 15px;
    padding-block-end: 20px;
    -webkit-transition: max-height .75s;
    transition: max-height .75s;
}

.read-more {
    background: none;
    border: none;
    color: #1199f9;
    cursor: pointer;
    font-size: 1em;
    outline: none;
}

    .read-more:hover {
        text-decoration: underline;
    }

    .read-more:focus {
        outline: none;
    }

    .read-more::-moz-focus-inner {
        border: 0;
    }

.hid {
    display: none;
}

.icon-container i {
    font-size: 2rem;
}

.icon-container i:nth-child(2){
    display: none;
}

.icon-container:hover i:nth-child(1){
    display: none;
    color: forestgreen
}

.icon-container:hover i:nth-child(2){
    display: block;
}

.control-label .text-info {
    display: inline-block;
}

.bgt{
    background-color: #FAFAFA;
    /*background-image:url("../lib/images/background_transporter_blur_x2.png");*/
}



/*Edit and replace this*/

/*.comment-block {
  color: rgb(0, 0, 0, .7);
  padding: 1rem;
  border: 1px solid #b4bbc6;
  border-radius: 0.1875rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  background-color: #FFFFFF;
  vertical-align: top;
}

.bottom-comment {
  color: #acb4c2;
  font-size: 0.875rem;
  margin-bottom: 25px;
  margin-top: 0px;
}

.comment-author {
  float: left;
  font-weight: 600;
  font-size: 14px;
  color: black;
}

.comment-date {
  color: #90949c;
  font-size: 11px;
  float: right;
  margin-left: 10px;
}

.comment-text {
  margin-bottom: 1.25rem;
  font-size: 14px;
  font-family: inherit;
  line-height: 20px;
}*/

/* You could style your text area how you would like here */

/*.active {
  margin-bottom: 1.25rem;
  font-size: 14px;
  font-family: inherit;
  width: 100%;
  line-height: 20px;
}*/





/*Feedback CSS*/
h1,
p {
    margin: 0;
    padding: 0;
    line-height: 1.5;
}

.app {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
}

#feedback .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.item {
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.radio {
    display: none;
}

    .radio ~ span {
        font-size: 3rem;
        filter: grayscale(100);
        cursor: pointer;
        transition: 0.3s;
    }

    .radio:checked ~ span {
        filter: grayscale(0);
        font-size: 4rem;
    }