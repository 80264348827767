/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

#sidebar::-webkit-scrollbar {
  display: auto;
  width: 1px;
}

#sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0;
  border-radius: 1px;
}

#sidebar::-webkit-scrollbar-thumb {
  background-color: #444444;
  border-radius: 1px;
}

#sidebar::-webkit-scrollbar-thumb:hover {
  overflow-y: scroll;
  background-color: #444444;
}

a {
  color: #94c045;
  text-decoration: none;
}

a:hover {
  color: #aacd6b;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
}

#main {
    z-index: 3;
    position: relative;
    margin-bottom: 50px;
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar {
  position: fixed;
  padding-bottom: 55px !important;
  top: 60px;
  right: 0;
  height: auto;
  bottom: 0;
  max-width: 300px;
  z-index: 996;
  padding: 20px;
  overflow-y: none;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  border-left: 2px solid rgba(1, 41, 112, 0.1);;
  background-color: #fff;
}

.toggle-sidebars{
  display: none;
  margin-left: -15px;
}

@media (max-width: 1299px) {
  .sidebar {
    right: -300px;
  }
}
@media (min-width: 1919px) {
  .sidebar {
    max-width: 430px !important;
  }
}

#slide {
  position: absolute;
  right: -100px;
  background: blue;
  transition: .25s;
}

@media (min-width: 1180) and (min-height: 820px) {
  .toggle-sidebars{
    display: block;
  }
  .headSide {
    width: 100%;
    justify-content: space-between;
  }
  .headSideCol {
      width: fit-content;
  }
}

@media (max-width: 912px) {
  .toggle-sidebars{
    display: block;
  }
  .headSide {
    width: 100%;
    justify-content: space-between;
  }
  .headSideCol {
      width: fit-content;
  }

@media (max-width: 768px) {
  /* .whor {
    width: 50%;
  } */

  @media screen and (min-width: 390px) and (max-height: 768px) {
    #main{
      margin-top: 60px;
    }
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1500px !important;
  }
}

@media (max-width: 620px) {
  .agent{
    border-top: .15px solid rgba(0, 0, 0, 0.185);
    margin-top: 10px;
    padding-top: 10px;
  }
  .sidebar-open{
    position: fixed;
    top: 60px;
    right: 0px;
    height: auto;
    bottom: 0;
    max-width: 300px;
    z-index: 1001;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent;
    box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
    border-left: 2px solid rgba(1, 41, 112, 0.1);;
    background-color: #fff;
  }
}
}

@media screen and (min-width: 1365px) and (max-width: 1366px) {
  .whor{
    width: fit-content;
  }
  #main{
    margin-top: 60px;
  }
  /* .main{
    width: 1067px !important;
  } */
}


/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 60px;
  z-index: 9997;
  transition: all 0.5s ease-in-out;
  background: rgb(255, 255, 255);
  border-bottom: 0.25px solid rgba(73, 73, 73, 0.068);
}
#header .logo h1 {
  font-size: 28px;
  margin: 0;
  line-height: 0;
  letter-spacing: 1px;
}
#header .logo h1 a, #header .logo h1 a:hover {
  color: #3c4133;
  text-decoration: none;
}
#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/

.headSide {
  /* position: fixed; */
  top: 60px;
  background: #fff;
  width: auto;
  padding: 1.5rem;
  align-items: center;
  display: flex;
  justify-items: center;
  transition: all 0.5s ease-in-out;
}

.navbar {
  background-color: #fcfcfc;
  justify-items: center;
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar a, .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: 600;
  white-space: nowrap;
  transition: 0.3s;
}
.navbar a i, .navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
  color: #94c045;
}
.navbar .getstarted, .navbar .getstarted:focus {
  background: #94c045;
  padding: 8px 18px;
  min-width: 23px;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
}
.navbar .getstarted:hover, .navbar .getstarted:focus:hover {
  background: #9fc658;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 75vh !important;
  background: url("../images/background_transporter_x2.png") top center;
  background-size: cover;
  position: relative;
  margin-bottom: -165px;
  z-index: 1;
}
#hero:before {
  content: "";
  background: rgba(60, 65, 51, 0.4);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#hero .hero-container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
#hero h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
}
#hero h2 {
  color: #eee;
  margin-bottom: 50px;
  font-size: 24px;
}
#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #94c045;
  text-transform: uppercase;
  color: #fff;
}
#hero .btn-get-started:hover {
  background: #94c045;
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}
@media (max-width: 768px) {
  #hero {
    height: 100vh;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #hero .hero-container {
    padding: 0 15px;
  }
}
@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding-bottom: 20px;
  overflow: hidden;
}

.section-bg {
  background-color: #f9fbf4;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}
.section-title h2 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 0;
  font-family: "Poppins", sans-serif;
  color: #646c55;
}
.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 0;
}
.breadcrumbs .breadcrumb-hero {
  text-align: center;
  background: #94c045;
  padding: 20px 0;
  color: #fff;
}
.breadcrumbs .breadcrumb-hero h2 {
  font-size: 32px;
  font-weight: 500;
}
.breadcrumbs .breadcrumb-hero p {
  font-size: 14px;
  margin-bottom: 0;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 30px 0;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #565e49;
  content: "/";
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: #86b03c;
  padding: 50px 0;
}
.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}
.cta p {
  color: #fff;
}
.cta .cta-btn {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 25px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}
.cta .cta-btn:hover {
  background: #aacd6b;
  border-color: #aacd6b;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #141611;
  z-index: 1000;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}
#footer .footer-top {
  background: #1c1f18;
  padding: 60px 0 30px 0;
}
#footer .footer-top .footer-info {
  margin-bottom: 30px;
}
#footer .footer-top .footer-info h3 {
  font-size: 26px;
  margin: 0 0 10px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 3px;
  color: #9fc658;
}
#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #fff;
}
#footer .footer-top .social-links a {
  display: inline-block;
  background: #3c4133;
  color: #fff;
  line-height: 1;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
#footer .footer-top .social-links a i {
  line-height: 0;
  font-size: 16px;
}
#footer .footer-top .social-links a:hover {
  background: #94c045;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top h4 {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top h4::before, #footer .footer-top h4::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
}
#footer .footer-top h4::before {
  right: 0;
  background: #3c4133;
}
#footer .footer-top h4::after {
  background: #94c045;
  width: 60px;
}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 5px 0 0 0;
  margin: 0;
}
#footer .footer-top .footer-links ul li {
  padding: 0 0 15px 0;
}
#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
}
#footer .footer-top .footer-links ul a:hover {
  color: #94c045;
}
#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}
#footer .footer-top .footer-contact p {
  line-height: 26px;
}
#footer .footer-top .footer-newsletter {
  margin-bottom: 30px;
}
#footer .footer-top .footer-newsletter input[type=email] {
  border: 0;
  padding: 6px 8px;
  width: 65%;
  border-radius: 4px 0 0 4px;
}
#footer .footer-top .footer-newsletter input[type=submit] {
  background: #94c045;
  border: 0;
  border-radius: 0 4px 4px 0;
  width: 35%;
  padding: 6px 0;
  text-align: center;
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
}
#footer .footer-top .footer-newsletter input[type=submit]:hover {
  background: #789d35;
}
#footer .copyright {
  text-align: center;
  padding-top: 30px;
}
#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .container {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding-bottom: 34px;
    background: #fff;
    max-width: 1250px;
  }
  .about .count-box {
    width: 100%;
  }
  .about .count-box i {
    display: block;
    font-size: 48px;
    color: #94c045;
    float: left;
    line-height: 0;
  }
  .about .count-box span {
    font-size: 28px;
    line-height: 24px;
    display: block;
    font-weight: 700;
    color: #646c55;
    margin-left: 60px;
  }
  .about .count-box p {
    padding: 5px 0 0 0;
    margin: 0 0 0 60px;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #646c55;
  }
  .about .count-box a {
    font-weight: 600;
    display: block;
    margin-top: 20px;
    color: #646c55;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    transition: ease-in-out 0.3s;
  }
  .about .count-box a:hover {
    color: #8b9578;
  }
  .about .content {
    font-size: 15px;
  }
  .about .content h3 {
    font-weight: 700;
    font-size: 24px;
    color: #3c4133;
  }
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  .about .content ul li {
    padding-bottom: 10px;
    padding-left: 28px;
    position: relative;
  }
  .about .content ul i {
    font-size: 24px;
    color: #94c045;
    position: absolute;
    left: 0;
    top: -2px;
  }
  .about .content p:last-child {
    margin-bottom: 0;
  }
  .about .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#94c045 50%, rgba(148, 192, 69, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  .about .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .about .play-btn::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(148, 192, 69, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  .about .play-btn:hover::after {
    border-left: 15px solid #94c045;
    transform: scale(20);
  }
  .about .play-btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
  }
  
  @-webkit-keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }