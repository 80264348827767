/* Message Zone */
.ellipsis {
  width: 5px;
  height: 5px;
  display: inline-block;
  background: lighten(#777777, 25%);
  border-radius: 50%;
  animation: bounce 1.3s linear infinite;
}

#tochat {
  position: fixed;
  right: 310px;
  bottom: 2.5rem;
  z-index: 999;
  background-color: white;
  border-radius: 20%;
  box-shadow: 0px 0px 20px rgb(1 41 112 / 10%);
  border: 2px solid rgba(1, 41, 112, 0.1);
}

  #tochat:hover {
      background-color: #f9ffef;
  }

#totop {
  position: fixed;
  right: 310px;
  bottom: 2.5rem;
  z-index: 999;
  background-color: white;
  border-radius: 20%;
  box-shadow: 0px 0px 20px rgb(1 41 112 / 10%);
  border: 2px solid rgba(1, 41, 112, 0.1);
}

  #totop:hover {
      background-color: #f9ffef;
  }

  @media (max-width: 1299px){
    #totop {
      right: 20px;
      bottom: 9rem;
    }
    #tochat {
      right: 20px;
      bottom: 9rem;
    }
  }
  @media screen and (min-width: 1910px) and (min-height: 1080px) {
    #totop {
      right: 450px;
    }
    #tochat {
      right: 450px;
    }
}

@media screen and (max-width: 1920px) and (max-height: 1000px) {
  #totop {
    right: 450px;
  }
  #tochat {
    right: 450px;
  }
}

@media (min-width: 355px) {
  #totop {
    right: 20px;
  }
  #tochat {
    right: 20px;
  }
}

.step {
  position: relative;
  min-height: 1em;
  color: gray;
}

  .step + .step {
      margin-top: 1.5em
  }

  .step > div:first-child {
      position: static;
      height: 0;
  }

  .step > div:not(:first-child) {
      margin-left: 1em;
      padding-left: 1em;
  }

  .step.step-active {
      color: #4285f4
  }

      .step.step-active .circle {
          background-color: #4285f4;
      }

/* Circle */
.circle {
  background: gray;
  position: relative;
  width: 1.2em;
  height: 1.2em;
  line-height: 1.5em;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 3px #fff;
}

  /* Vertical Line */
  .circle:after {
      content: ' ';
      position: absolute;
      display: block;
      top: 1px;
      right: 50%;
      bottom: 1px;
      left: 50%;
      height: 100%;
      width: 1px;
      transform: scale(1, 2);
      transform-origin: 50% -100%;
      background-color: rgba(0, 0, 0, 0.25);
      z-index: -1;
  }

.step:last-child .circle:after {
  display: none
}

/* Stepper Titles */
.title {
  line-height: 1.5em;
  font-weight: bold;
}

.caption {
  font-size: 0.8em;
}

.one {
  animation-delay: 0.6s;
}
.two {
  animation-delay: 0.5s;
}
.three {
  animation-delay: 0.8s;
}

.bottom-bar {
  position: absolute;
  width: 100%;
  /* height: 55px; */
  bottom: auto;
  background: white;
  border-radius: 0 0 10px 10px;
}
.input {
  padding: 7px;
  width: 100%;
  resize: none;
  position: absolute;
  border: 0;
  top: 13px;
  background: white;
  color: black;

}

.input::placeholder {
  color: #94c045;
}

input:focus {
  color: #777777;
  outline: 0;
}

.send {
  position: absolute;
  border: 0;
  font-size: 1em;
  color: #A1E2D9;
  top:  19px;
  opacity: 0.8;
  right: 17px;
  cursor: pointer;
  outline: 0;
}

.send:hover {
  transform: scale(1.1);
  transition: all .3s ease-in-out;
  color: primary;
}



@keyframes bounce {
  30% { transform: translateY(-2px); }
  60% { transform: translateY(0px); }
  80% { transform: translateY(2px); }
  100% { transform: translateY(0px); opacity: 0.5;  }
}